import Instagram from "../assets/icons/instagram.svg";
import LinkedIn from "../assets/icons/linkedin.svg";
import TikTok from "../assets/icons/tiktok.svg";
import GoldLogo from "../assets/images/gold-logo.svg";

const Footer = () => {
    const links = [
        {
            name: 'About',
            href: '/',
        },
        {
            name: 'Membership',
            href: '/',
        },
        {
            name: 'Book Now',
            href: '/',
        },
        {
            name: 'Register your vehicle',
            href: '/',
        },
        {
            name: 'Terms and Conditions',
            href: '/',
        },
        {
            name: 'Privacy Policy',
            href: '/',
        },
    ];

    return (
        <div className="mt-auto px-8 border-t border-gray4">
            <div className="flex container gap-12
            mx-auto px-4 py-6 items-center justify-center flex-col">
                <div className="gap-8 flex flex-wrap flex-col md:flex-row items-center md:items-auto">
                    {
                        links.map(link => <a
                            key={link.name}
                            className="font-normal text-sm uppercase"
                            href={link.href}>
                            { link.name }
                        </a>)
                    }
                </div>
                <div className="flex gap-8">
                    <img src={Instagram} alt="Instagram" />
                    <img src={LinkedIn} alt="Instagram" />
                    <img src={TikTok} alt="Instagram" />
                </div>
                <div>
                    <img src={GoldLogo} alt="Goldsainte" />
                </div>
                <p className="text-center text-gray2 text-sm">
                    &copy;Copyright 2023. All rights reserved.
                </p>
            </div>
        </div>
    );
};

export default Footer;
