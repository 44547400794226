import axios from "axios";

export const getSubscriptionPricing = async () => {
    try {
        const {data} = await axios.get(
            `${process.env.REACT_APP_API_URL}/Pricing/pricing/GetSubsccriptionCost`,
        );
        return data?.data;
    } catch (e: any) {
        console.log(e.message);
    }
};
