import {useEffect, useMemo, useState} from "react";
import MembershipCard from '../../assets/images/membership-card.svg';
import {getSubscriptionPricing} from "../../api/pricing";
import {useRecoilValue} from "recoil";
import {
    familyMembersState,
    membersState,
    paymentMethodIdState,
    stripeCustomerIdState, userState
} from "../../store/onboarding/onboarding";
import {addSubscription} from "../../api/stripe";
import LoadingModal from "../../components/LoadingModal";
import {dollarFormattedValue} from "../../utils/misc";

// types
interface PricingInfo {
    yearlyCost: number,
    membershipCost: number,
    tax: number
}


const PurchaseConfirmation = () => {
    // state
    const members = useRecoilValue(membersState);
    const familyMembers = useRecoilValue(familyMembersState);
    const customerId = useRecoilValue(stripeCustomerIdState);
    const paymentMethodId = useRecoilValue(paymentMethodIdState);
    const user = useRecoilValue(userState);

    const [pricing, setPricing] = useState<PricingInfo>({
        yearlyCost: 0,
        membershipCost: 0,
        tax: 0
    });

    const getPricing = async () => {
        const pricing: PricingInfo = await getSubscriptionPricing();
        setPricing(pricing);
    };

    // local state
    const [confirming, setConfirming] = useState(false);

    const onConfirmPurchase = async () => {
        try {
            setConfirming(true);
            await addSubscription({
                name: 'subscription',
                customerId,
                paymentMethodId,
                subtotal: subTotal,
                tax: pricing.tax,
                startDate: '2022-11-15T19:45:20.189Z',
                dueDat: '2022-11-15T19:45:20.189Z',
                currency: 'usd',
                members: [...members, ...familyMembers],
                couponCode: user.couponCode
            });

            (window as any).location.href = "https://www.goldsainte.com/purchased";
            return;

            // @TODO: navigate somewhere
        } catch (e: any) {
            console.log(e.message);
        } finally {
            setConfirming(false);
        }
    };

    useEffect(() => {
        getPricing().then();
    }, []);


    const subTotal = useMemo(
        () => {
            return pricing.yearlyCost +
                ([...familyMembers ?? []]?.length * pricing.membershipCost);
        },
        [pricing, members]
    );

    const total = useMemo(
        () => {
            return subTotal * (1 + pricing.tax);
        },
        [subTotal]
    );

    const allMembers = useMemo(
        () => {
            return [
                ...(members ?? []),
                ...(familyMembers ?? [])
            ]?.map((member: any) => ({
                ...member,
                firstName: member.firstName,
                lastName: member.lastName,
                relationship: member.relationship ?? 'general'
            }))
        },
        [members, familyMembers]
    );

    return <div className="flex flex-col items-center pt-2 md:pt-20 flex-1 font-covik">
        <div className="mb-10 flex">
            <span className="font-chiffon uppercase
            text-[26px] leading-[30px]
            md:text-[60px] md:leading-[70px] px-12 text-center">
                Luxury Travel Reimagined for the Modern Era
            </span>
        </div>
        <div className="mb-12 flex gap-2 md:gap-12 flex-col md:flex-row">
            <div className="flex-1 flex justify-center md:flex-end md:justify-end mb-4 md:mb[auto]">
                <img src={MembershipCard} className="w-[353px] h-[191px]" alt="Membership card"/>
            </div>
            <div className="flex-1 flex flex-col gap-3">
                <div className="px-4 md:px-0 text-center md:text-left">
                    <span className="text-lg leading-[18px]">Purchase confirmation</span>
                </div>
                <div className="px-4 md:px-0 text-center md:text-left">
                    <span className="text-lg text-[13px] leading-[15px]">One-Time Initiation Fee of {dollarFormattedValue(pricing.yearlyCost)}</span>
                </div>
                <p className="text-lg text-[14px]
                 px-4 md:px-0 w-[375px]
                leading-[17px] mb-[30px] font-covik">
                    You have selected a Goldsainte One membership and will
                    now have access to any of our Goldsainte Connected City
                    locations. Find your new digital card and additional
                    membership details the account section.
                </p>
                <div className="flex flex-col mb-6 px-4 md:px-0">
                    <span className="text-gold font-medium text-sm">
                        Summary
                    </span>
                    <div className="font-medium text-base flex gap-4">
                        <div className="w-[75px]">
                            <span>Subtotal</span>
                        </div>
                        <div className="text-right w-[90px]">
                        <span className="text-center">{ dollarFormattedValue(subTotal) }</span>
                        </div>
                    </div>
                    <div className="font-medium text-base flex gap-4">
                        <div className="w-[75px]">
                            <span>Tax</span>
                        </div>
                        <div className="text-right w-[90px]">
                            <span className="text-center">{ dollarFormattedValue(pricing.tax * subTotal) }</span>
                        </div>
                    </div>
                    <div className="font-medium text-base flex gap-4">
                        <div className="w-[75px]">
                            <span className="font-bold">Total</span>
                        </div>
                        <div className="text-right w-[90px]">
                        <span className="text-center">{ dollarFormattedValue(total) }</span>
                        </div>
                    </div>
                </div>
                <div className="px-4 md:px-0 text-center md:text-left">
                    <button
                        onClick={onConfirmPurchase}
                        className="bg-gold text-white uppercase focus:outline-none
                        disabled:bg-gray-300
                        py-2 w-[138px] text-sm font-medium leading-[20px] px-10 rounded-[20px]"
                            type="button">
                        Apply
                    </button>
                </div>
            </div>
        </div>
        <LoadingModal text="Confirming purchase..." show={confirming} handleClose={() => {}} />
    </div>
};

export default PurchaseConfirmation;
