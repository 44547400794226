import './styles/tailwind.css';
import { RecoilRoot } from 'recoil';
import { Route, Routes, Outlet, Navigate, useLocation } from "react-router-dom"

// general components
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";

import {
    Elements,
} from "@stripe/react-stripe-js";

// auth components
import Login from "./pages/auth/Login";
import VerificationCode from "./pages/auth/VerificationCode";
import Register from "./pages/auth/Register";
import PurchaseConfirmation from "./pages/auth/PurchaseConfirmation";
import {loadStripe} from "@stripe/stripe-js";

const AuthWrapper = () => {
    const location = useLocation();
    const token = !!localStorage.getItem("token")!;

    return token ? (
        <Outlet />
    ) : (
        <Navigate to="/login" replace state={{ from: location }} />
    );
};

let stripePromise: any = null;
if (process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
    return (
        <RecoilRoot>
            <Elements stripe={stripePromise}>
                <div className="flex flex-col min-h-screen font-covik">
                    <Header/>
                    <main className="flex-1">
                        <Routes>
                            <Route element={<AuthWrapper/>}>
                                <Route path="/" element={<Home/>}/>
                            </Route>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/verify" element={<VerificationCode/>}/>
                            <Route path="/register" element={<Register/>}/>
                            <Route path="/purchase-confirmation" element={<PurchaseConfirmation/>}/>
                            <Route path="*" element={<Navigate to="/" replace/>}/>
                        </Routes>
                    </main>
                    <Footer/>
                </div>
            </Elements>
        </RecoilRoot>
    );
}

export default App;
