import {atom} from "recoil";

export const phoneState = atom({
    key: 'onboarding_phone',
    default: '',
});

export const verifiedRawState = atom({
    key: 'verified_raw_phone',
    default: '',
});

export const isPhoneVerifiedState = atom({
    key: 'onboarding_phone_verified',
    default: false
});

export const emailState = atom({
    key: 'onboarding_email',
    default: '',
});

export const addressState = atom({
    key: 'onboarding_address',
    default: '',
});

export const userState = atom({
    key: 'onboarding_user',
    default: {
        email: null,
        phone: null,
        firstName: null,
        lastName: null,
        phoneVerified: false,
        driverLicenseNumber: null,
        driverLicenseExpirationDate: null,
        couponCode: ''
    }
})

export const providerState = atom({
    key: 'onboarding_provider',
    default: '',
});

export const membersState = atom<any[]>({
    key: 'onboarding_members',
    default: [],
});

export const familyMembersState = atom<any[]>({
    key: 'onboarding_family_members',
    default: [],
});

export const MAX_FAMILY_SHARING_MEMBERS = 2;


export const stripeCustomerIdState = atom<string>({
    key: 'stripe_customer_id',
    default: undefined,
});

export const paymentMethodIdState = atom<string>({
    key: 'payment_method_id',
    default: undefined,
});

export const cardLast4State = atom<string>({
    key: 'card_last4',
    default: undefined,
});

export const avatarImageUrlState = atom<string | undefined | null>({
    key: 'avatar+image_image_uri',
    default: undefined
});

export const driverLicenseImageUrlState = atom<string | undefined | null>({
    key: 'driver_license_image_uri',
    default: undefined
});
