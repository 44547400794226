import { useNavigate } from "react-router-dom";
import {useState, useRef, useEffect} from "react";
import LoadingModal from "../../components/LoadingModal";
import {getProfile, redirectValidateToken, sendPhoneNumberValidationSms, validatePhoneNumber} from "../../api/account";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import axios from "axios";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {phoneState, verifiedRawState} from "../../store/onboarding/onboarding";
import {stripPhoneNumber} from "../../utils/misc";

const Login = () => {
    const navigate = useNavigate();
    const [isPlaying] = useState(true);
    const phone = useRecoilValue(phoneState);
    const setVerifiedRawPhone = useSetRecoilState(verifiedRawState);

    const digitRef1 = useRef<HTMLInputElement>(null);
    const digitRef2 = useRef<HTMLInputElement>(null);
    const digitRef3 = useRef<HTMLInputElement>(null);
    const digitRef4 = useRef<HTMLInputElement>(null);

    const [digit1, setDigit1] = useState<string>();
    const [digit2, setDigit2] = useState<string>();
    const [digit3, setDigit3] = useState<string>();
    const [digit4, setDigit4] = useState<string>();

    const [hasError, setHasError] = useState(false);
    const [validating, setValidating] = useState(false);
    const [resending, setResending] = useState(false);

    const verifyCode = async (code: string) => {
        (digitRef1.current as any).blur();
        (digitRef2.current as any).blur();
        (digitRef3.current as any).blur();
        (digitRef4.current as any).blur();

        setValidating(true);
        const strippedPhone = stripPhoneNumber(phone);

        try {
            const data: any = await validatePhoneNumber(strippedPhone, code);
            setVerifiedRawPhone(strippedPhone);
            if (data.isUnauthorized === false) {
                const {data: userData} = data;
                if (userData.isRegistered) {
                    // await redirectValidateToken(userData.jwtToken);
                    (window as any).location.href = "https://booking.goldsainte.com/";
                } else {
                    navigate('/register');
                }
            } else {
                setHasError(true);
                setTimeout(() => {
                    setHasError(false);
                    resetDigits();
                    setValidating(false);
                    (digitRef1.current as any).focus();
                }, 3000);
            }
        } catch (e: any) {
            console.log(e.message);
        } finally {
            setValidating(false);
        }
    };

    const resetDigits = () => {
        setDigit1('');
        setDigit2('');
        setDigit3('');
        setDigit4('');
    };

    const onPaste = (e: any) => {
        const text = e.clipboardData.getData('Text');
        if (text?.length === 4) {
            const [d1, d2, d3, d4] = text.split('');
            setDigit1(d1);
            setDigit2(d2);
            setDigit3(d3);
            setDigit4(d4);
        }
    };

    const resend = async () => {
        try {
            await sendPhoneNumberValidationSms(stripPhoneNumber(phone));
            setResending(true);
        } catch (e: any) {
            console.log(e);
        } finally {
            setResending(false);
        }
    };

    useEffect(() => {
        const code = `${digit1}${digit2}${digit3}${digit4}`;
        if (code.length === 4)
            verifyCode(code).then();

        if (!phone)
            navigate('/login');
    }, [digit4]);

    const digitClass = `
    border-b outline-none text-3xl border-gray-300 
    h-20 w-[52px] text-center form-control leading-[64px] text-5xl
    `;

    return <div className="flex flex-col items-center pt-20 flex-1">
        <div className="mb-1 flex mb-8">
            <span className="text-[28px] md:text-[40px] font-bold leading-[60px] px-12 text-center">
                Verification Code
            </span>
        </div>
        <div className="flex mb-16 gap-6">
            <input value={digit1}
                   autoFocus={true}
                   onPaste={onPaste}
                   type="number"
                   ref={digitRef1}
                   onChange={(e) => {
                       setDigit1(e.target.value);
                       (digitRef2.current as any).focus();
                   }}
                   className={digitClass}
                   id="d1"
                   maxLength={1}/>
            <input value={digit2}
                   type="number"
                   ref={digitRef2}
                   onChange={(e) => {
                       setDigit2(e.target.value);
                       (digitRef3.current as any).focus();
                   }}
                   className={digitClass}
                   id="d2"
                   maxLength={1}/>
            <input
                value={digit3}
                ref={digitRef3}
                onChange={(e) => {
                    setDigit3(e.target.value);
                    (digitRef4.current as any).focus();
                }}
                className={digitClass}
                type="number"
                id="d3"
                maxLength={1}/>
            <input
                value={digit4}
                ref={digitRef4}
                onChange={(e) => {
                    setDigit4(e.target.value);
                }}
                className={digitClass}
                type="number"
                id="d4"
                maxLength={1}/>
        </div>
        <div className="mb-16">
            <div className="w-[80px] h-[80px] items-center justify-center flex">
                <CountdownCircleTimer
                    isPlaying={isPlaying}
                    size={80}
                    duration={60}
                    colors="#d9d9d9"
                    trailColor="#ffffff"
                    strokeWidth={2}
                    rotation="counterclockwise"
                    onComplete={() => ({ shouldRepeat: false, delay: 2 })}
                >
                    {({ remainingTime, color }) => (
                        <div className="text-[13px] leading-[17px]">
                            {remainingTime} Secs
                        </div>
                    )}
                </CountdownCircleTimer>
            </div>
        </div>
        <div className="mb-[142px]">
            <span onClick={resend} className="text-sm cursor-pointer">
                Haven't received a code?
            </span>
        </div>
        <div className="mt-8">
            {hasError && <span className="text-red">
                Invalid code. Please try again
            </span>}
        </div>
        <LoadingModal text="Validating code..." show={validating} handleClose={() => { setValidating(false); }} />
        <LoadingModal text="Code resent" show={resending} handleClose={() => { setResending(false); }} />
    </div>
};

export default Login;
