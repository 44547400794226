export const formatPhoneNumber = (value: string) => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
    )}-${phoneNumber.slice(6, 10)}`;
}


export const stripPhoneNumber = (phone: string): string => {
    // Use a regular expression to match non-digit characters in the phone number
    const regex = /\D/g;
    // Use the replace method to remove the matched characters from the phone number
    const strippedPhone = phone.replace(regex, '');
    // Concatenate the "+1" prefix with the stripped phone number
    return "+1" + strippedPhone;
};



export const numberWithCommas = (x: string) =>  {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const dollarFormattedValue = (value: number) => `$${numberWithCommas(value.toFixed(2))}`;


