import Logo from "../assets/images/text-logo.svg";
import Hamburger from '../assets/icons/hamburger.svg';

const Header = () => {
    return (
        <div className="h-32">
            <div className="container mx-auto px-4 py-6 flex gap-8">
                {/*<img src={Hamburger} alt="Menu" className="" />*/}
                <a href="/">
                    <img src={Logo} alt="Goldsainte" className="w-[227px] h-[46px]" />
                </a>
            </div>
        </div>
    );
};

export default Header;
