import { useNavigate } from "react-router-dom";
import Visa from '../../assets/icons/cards/visa.svg';
import MasterCard from '../../assets/icons/cards/mastercard.svg';
import Amex from '../../assets/icons/cards/amex.svg';
import Plus from '../../assets/icons/plus.svg';
import '../../styles/stripe.css';

import {
    CardElement, useElements,
    useStripe,
} from "@stripe/react-stripe-js";

import Member from "../../components/Member";
import { Member as MemberType} from "../../types/member";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    familyMembersState,
    membersState,
    paymentMethodIdState,
    stripeCustomerIdState,
    userState, verifiedRawState
} from "../../store/onboarding/onboarding";
import {formatPhoneNumber} from "../../utils/misc";
import AddEditMember from "../../components/AddEditMember";
import { useState} from "react";
import {ImageDropzone} from "../../components/DropZone";
import {register, updateProfile} from "../../api/account";
import axios from "axios";
import LoadingModal from "../../components/LoadingModal";
import {getPaymentIntent} from "../../api/stripe";
import { loadStripe } from '@stripe/stripe-js';


const PaymentForm = () => {
    let stripe;
    if (process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
        stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Covik Sans, Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
    };

    return <CardElement id="card" options={CARD_ELEMENT_OPTIONS} />
};

const Register = () => {
    const navigate = useNavigate();
    const [user, setUser] = useRecoilState<any>(userState);
    const members = useRecoilValue(membersState);
    const familyMembers = useRecoilValue(familyMembersState);
    const [memberVisible, setMemberVisible] = useState(false);
    const [familyMembersVisible, setFamilyMembersVisible] = useState(false);
    const [licenseImage, setLicenseImage] = useState<string | undefined>();
    const [creating, setCreating] = useState(false);
    const stripe = useStripe();
    const elements: any = useElements();
    const setPaymentMethodId = useSetRecoilState(paymentMethodIdState);
    const setStripeCustomerId = useSetRecoilState(stripeCustomerIdState);
    const verifiedRawPhone = useRecoilValue(verifiedRawState);

    const onDriverLicenseUpload = (url: string): any => {
        if (url)
            setLicenseImage(url);
    }

    const next = async () => {
        if (!process.env.REACT_APP_WEBSITE_GUID || !stripe) return;
            setCreating(true);

            try {
                const createdUser: any = await register({
                    websiteGuid: process.env.REACT_APP_WEBSITE_GUID,
                    phone: verifiedRawPhone,
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    AcceptTermsAndConditions: true,
                    couponCode: user.couponCode
                });
                axios.defaults.headers.common['Authorization'] = createdUser.jwtToken;

                await updateProfile({
                    clientGuid: createdUser.clientGuid,
                    driverLicenceUrl: licenseImage,
                    email: createdUser.email,
                    firstName: createdUser.firstName,
                    guid: createdUser.clientGuid,
                    lastName: createdUser.lastName,
                    locations: [],
                    phone: createdUser.phone,
                    profilePictureUrl: ""
                });


                try {
                    const cardElement = elements.getElement(CardElement);
                    const { data } = await getPaymentIntent();
                    console.log('d = ', data);

                    const { error, setupIntent }: any = await stripe.confirmCardSetup(data.intentClientSecret, {
                        payment_method: {
                            card: cardElement,
                        }
                    });


                    if (setupIntent) {
                        setPaymentMethodId(setupIntent.payment_method as string);
                        setStripeCustomerId(data.customerId as string);
                    } else {
                        alert('Could not register, error = no payment intent.');
                    }

                    // @ts-ignore
                    // navigation.navigate(ON_BOARDING_ROUTES.Memberships, {});
                } catch (e: any) {
                    console.log(e);
                    if (e.message) {
                        throw Error("Failed to add card.");
                    }
                } finally {
                }

                navigate('/purchase-confirmation');
            } catch (e: any) {
                alert('Could not register account')
            } finally {
                setCreating(false);
            }
    };

    const isValid = () => {
        return !(!user.firstName?.length ||
            !user.lastName?.length ||
            !user.phone?.length ||
            !user.driverLicenseExpirationDate?.length ||
            !user.driverLicenseNumber?.length ||
            !user.email?.length);
    };

    const updateField = (field: string, value: string) => {
        setUser((prev: any) => ({
            ...prev,
            [field]: value
        }))
    };

    return <div className="flex flex-col items-center pt-0 flex-1">
        <div className="mb-1 flex">
            <span className="text-[24px] leading-[auto] md:text-[40px] md:leading-[60px] px-12 font-bold text-center">
                Apply for a Goldsainte One Membership
            </span>
        </div>
        <div className="mt-4 md:mt-[auto] mb-[50px] text-center w-[220px] md:w-[500px]">
            <span className="text-[15px] font-normal leading-[17px]">
                To start your experience, <br className="block md:hidden" />
                please leave your details below
            </span>
        </div>
        <div className="mb-10 flex flex-col w-[100%] md:w-[auto]
        px-4 md:px-0
        md:flex-row md:gap-[80px] flex-wrap">
            <div className="flex-1 flex-col">
                <div className="mb-6">
                    <input
                        type="text"
                        required
                        value={user.firstName ?? ''}
                        onChange={(e) => {
                            updateField('firstName', e.target.value);
                        }}
                        className="form-input py-3 block w-full leading-5 transition
                    placeholder-gray1 text-[16px] w-[100%] md:w-[460px]
                    duration-150 ease-in-out bg-white border-b border-gray-300 placeholder-gray-500
                    focus:outline-none focus:border-primary focus:shadow-outline-primary active:bg-gray-50
                    px-0 py-4 active:text-gray-800"
                        placeholder="First name"
                    />
                </div>
                <div className="mb-6">
                    <input
                        type="text"
                        required
                        value={user.lastName ?? ''}
                        onChange={(e) => {
                            updateField('lastName', e.target.value);
                        }}
                        className="form-input py-3 px-4 block w-full leading-5 transition
                    placeholder-gray1 text-[16px] w-[100%] md:w-[460px]
                    duration-150 ease-in-out bg-white border-b border-gray-300 placeholder-gray-500
                    focus:outline-none focus:border-primary focus:shadow-outline-primary active:bg-gray-50
                    px-0 py-4 active:text-gray-800"
                        placeholder="Last name"
                    />
                </div>
                <div className="mb-12 ">
                    <input
                        type="text"
                        required
                        value={user.address ?? ''}
                        onChange={(e) => {
                            updateField('address', e.target.value);
                        }}
                        className="form-input py-3 px-4 block w-full leading-5 transition
                    placeholder-gray1 text-[16px] w-[100%] md:w-[460px]
                    duration-150 ease-in-out bg-white border-b border-gray-300 placeholder-gray-500
                    focus:outline-none focus:border-primary focus:shadow-outline-primary active:bg-gray-50
                    px-0 py-4 active:text-gray-800"
                        placeholder="Address"
                    />
                </div>
                <div className="mb-6 cursor-pointer group">
                    <div className="mb-3 md:px-0">
                        <span className="font-bold text-base leading-[24px]">
                            Drivers License
                        </span>
                    </div>
                    <ImageDropzone onDownloaded={onDriverLicenseUpload} />
                </div>
                <div className="mb-6 md:px-0">
                    <input
                        type="text"
                        required
                        value={user.driverLicenseNumber ?? ''}
                        onChange={(e) => {
                            updateField('driverLicenseNumber', e.target.value);
                        }}
                        className="form-input py-3 px-4 block w-full leading-5 transition
                    placeholder-gray1 text-[16px] w-[100%] md:w-[460px]
                    duration-150 ease-in-out bg-white border-b border-gray-300 placeholder-gray-500
                    focus:outline-none focus:border-primary focus:shadow-outline-primary active:bg-gray-50
                    px-0 py-4 active:text-gray-800"
                        placeholder="Drivers license number"
                    />
                </div>
                <div className="mb-6 md:px-0">
                    <input
                        type="text"
                        required
                        value={user.driverLicenseExpirationDate}
                        onChange={(e) => {
                            updateField('driverLicenseExpirationDate', e.target.value);
                        }}
                        className="form-input py-3 px-4 block w-full leading-5 transition
                    placeholder-gray1 text-[16px] w-[100%] md:w-[460px]
                    duration-150 ease-in-out bg-white border-b border-gray-300 placeholder-gray-500
                    focus:outline-none focus:border-primary focus:shadow-outline-primary active:bg-gray-50
                    px-0 py-4 active:text-gray-800"
                        placeholder="Expiration date"
                    />
                </div>
            </div>
            <div className="flex-1 flex-col">
                <div className="mb-6">
                    <input
                        type="text"
                        required
                        value={user.phone ?? ''}
                        onChange={(e) => {
                            const formattedPhone = formatPhoneNumber(e.target.value);
                            updateField('phone', formattedPhone);
                        }}
                        className="form-input py-3 px-4 block w-full leading-5 transition
                    placeholder-gray1 text-[16px] w-[100%] md:w-[460px]
                    duration-150 ease-in-out bg-white border-b border-gray-300 placeholder-gray-500
                    focus:outline-none focus:border-primary focus:shadow-outline-primary active:bg-gray-50
                    px-0 py-4 active:text-gray-800"
                        placeholder="Phone number"
                    />
                </div>
                <div className="mb-12">
                    <input
                        type="text"
                        required
                        value={user.email ?? ''}
                        onChange={(e) => {
                            updateField('email', e.target.value);
                        }}
                        className="form-input py-3 px-4 block w-full leading-5 transition
                    placeholder-gray1 text-[16px] w-[100%] md:w-[460px]
                    duration-150 ease-in-out bg-white border-b border-gray-300 placeholder-gray-500
                    focus:outline-none focus:border-primary focus:shadow-outline-primary active:bg-gray-50
                    px-0 py-4 active:text-gray-800"
                        placeholder="Email"
                    />
                </div>
                <div className="mb-6">
                    <div className="mb-2 md:mb-6">
                        <span className="font-bold text-base leading-[24px]">
                            Credit Card
                        </span>
                    </div>
                    <PaymentForm />
                    <div className="mt-5 flex gap-2 items-center">
                        <div>
                            <span className="font-normal text-[13px] leading-[15px] text-gray5">
                            We accept:
                        </span>
                        </div>
                        <div className="flex gap-3 items-center justify-center">
                            <img src={Visa} alt="Visa" />
                            <img src={MasterCard} alt="MasterCard" />
                            <img src={Amex} alt="Amex" />
                        </div>
                    </div>
                </div>
                <div className="mb-12">
                    <input
                        type="text"
                        required
                        value={user.couponCode ?? ''}
                        onChange={(e) => {
                            updateField('couponCode', e.target.value);
                        }}
                        className="form-input py-3 px-4 block w-full leading-5 transition
                    placeholder-gray1 text-[16px] w-[100%] md:w-[460px]
                    duration-150 ease-in-out bg-white border-b border-gray-300 placeholder-gray-500
                    focus:outline-none focus:border-primary focus:shadow-outline-primary active:bg-gray-50
                    px-0 py-4 active:text-gray-800"
                        placeholder="Coupon Code"
                    />
                </div>
                <div className="mb-8">
                    <div
                        onClick={() => setMemberVisible(true)}
                        className="flex justify-between cursor-pointer">
                        <span className="font-bold text-base leading-[24px]">
                            Add Additional Member
                        </span>
                        <img src={Plus} alt="Add additional member"/>
                    </div>
                    <div className="leading-[17px]">
                        <span className="font-normal text-[11px]">(Included in membership)</span>
                    </div>
                    <div>
                        { members.map((member: MemberType) => <Member
                            isFamily={false}
                            member={member}
                            key={member.phone}
                        />) }
                    </div>
                </div>
                <div className="mb-6 flex flex-col items-center ">
                    <div
                        className="flex justify-between cursor-pointer flex-col">
                        <span className="font-bold text-base leading-[24px] text-center">
                             Have family members that you want <br />
                             to add to your membership plan? <br />
                        </span>
                        <div className="mt-2 md:mt-0">
                             <span className="text-[14px] font-bold">
                                 An additional $12/month per member
                            </span>
                        </div>
                    </div>
                    <div className="leading-[17px] mb-4">
                        <span className="font-normal text-[11px]">(Up to two additional members)</span>
                    </div>
                    <div className="flex justify-end w-[100%]">
                        <img className="cursor-pointer" onClick={() => setFamilyMembersVisible(true)}
                            src={Plus} alt="Add additional member"/>
                    </div>
                    <div>
                        { familyMembers.map((member: MemberType) => <Member
                            isFamily={true}
                            member={member}
                            key={member.phone}
                        />) }
                    </div>
                </div>
            </div>
        </div>
        <div className="mb-32">
            <button
                onClick={next}
                disabled={!isValid()}
                className="bg-gold text-white uppercase focus:outline-none
                        disabled:bg-gray-300
                        py-2 w-[138px] text-sm font-medium leading-[20px] px-10 rounded-[20px]"
                type="button">
                Apply
            </button>
        </div>
        <AddEditMember show={memberVisible}
                       member={null}
                       isFamily={false}
                       handleClose={() => setMemberVisible(false)} />
        <AddEditMember show={familyMembersVisible}
                       member={null}
                       isFamily={true}
                       handleClose={() => setFamilyMembersVisible(false)} />
        <LoadingModal text="Creating your account..." show={creating} handleClose={() => {}} />
    </div>
};

export default Register;
