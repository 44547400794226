import { useNavigate } from "react-router-dom";
import {useEffect, useState} from "react";
import LoadingModal from "../../components/LoadingModal";
import { sendPhoneNumberValidationSms } from "../../api/account";
import { formatPhoneNumber } from "../../utils/misc";
import { phoneState } from "../../store/onboarding/onboarding";
import { useRecoilState } from "recoil";

const Login = () => {
    const navigate = useNavigate()
    const [phone, setPhone] = useRecoilState(phoneState);
    const [sending, setSending] = useState(false);

    const handleInput = (e: any) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setPhone(formattedPhoneNumber);
    };

    // useEffect(() => {
    //     const user = localStorage.getItem('token')!;
    //     if (!!user) {
    //         navigate('/');
    //     }
    // }, []);

    const signIn = async (e: any) => {
        try {
            setSending(true);
            await sendPhoneNumberValidationSms(phone);
            setPhone(phone);
            navigate('/verify');
        } catch (e: any) {

        } finally {
            setSending(false);
        }
    };

    const isValid = () => phone?.length === 14;

    return <div className="flex flex-col items-center pt-0 md:pt-20 flex-1">
        <div className="flex">
            <span className="text-[24px] leading-[auto] md:text-[40px] font-bold md:leading-[60px] px-12 text-center">
                Apply for a Goldsainte One Membership
            </span>
        </div>
        <div className="mt-4 md:mt-[auto] mb-[50px] text-center w-[220px] md:w-[500px]">
            <span className="text-[15px] font-normal leading-[17px]">
                To start your experience, <br className="block md:hidden" />
                please leave your details below
            </span>
        </div>
        <div className="mb-14 w-[100%] px-4 md:w-[auto] md:px-[auto]">
            <label htmlFor="phone" className="block mb-2 font-bold text-base leading-[24px] leading-[24px]">
                Phone number
            </label>
            <div className="relative">
                <input
                    onChange={(e) => handleInput(e)} value={phone}
                    id="phone"
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            if (isValid())
                                signIn(event);
                        }
                    }}
                    type="text"
                    required
                    className="form-input py-3 px-4 block leading-5 transition
                    placeholder-gray1 text-[16px] w-[100%] md:w-[460px]
                    duration-150 ease-in-out bg-white border-b border-gray-300 placeholder-gray-500
                    focus:outline-none focus:border-primary focus:shadow-outline-primary active:bg-gray-50
                    px-0 py-4 active:text-gray-800"
                    placeholder="310-800-1251"
                />
            </div>
            <LoadingModal text="Sending confirmation SMS..." show={sending} handleClose={() => {}} />
        </div>
        <div className="mb-24">
            <div className="md:w-2/3">
                <button onClick={signIn}
                        disabled={!isValid()}
                        className="bg-gold text-white uppercase focus:outline-none
                        disabled:bg-gray-300
                        py-2 w-[138px] text-sm font-medium leading-[20px] px-10 rounded-[20px]"
                        type="button">
                    Apply
                </button>
            </div>
        </div>
        <div className="hidden">1.0.1</div>
    </div>
};

export default Login;
