import {Link, useNavigate} from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();
    const signOut = () => {
        localStorage.removeItem('token');
        navigate('/');
    };

    return <div className="h-screen flex flex-col items-center justify-center">
        <nav>
            <ul>
                <li><Link to="/">Home</Link></li>
                <li><a onClick={signOut} href="">Sign out</a></li>
            </ul>
        </nav>
        <footer className="p-4 mt-16">
            <div className="container mx-auto flex justify-around items-center gap-6">
                <a href="#" className="text-gray-400">
                    About
                </a>
                <a href="#" className="text-gray-400">
                    Membership
                </a>
                <a href="#" className="text-gray-400">
                    Book now
                </a>
                <a href="#" className="text-gray-400">
                    Register Your Vehicle
                </a>
                <a href="#" className="text-gray-400">
                    Terms and Conditions
                </a>
                <a href="#" className="text-gray-400">
                    Privacy Policy
                </a>
            </div>
            <div>
                <span className="text-gray-400 text-sm">
                    Copyright 2023. All Rights Reserved.
                </span>
            </div>
        </footer>
    </div>
}

export default Home;
