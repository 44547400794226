import React from 'react';
import { useDropzone } from 'react-dropzone';
import { initializeApp } from "firebase/app";
import {  getStorage, ref, uploadBytesResumable, getDownloadURL  } from "firebase/storage";
import Camera from "../assets/images/camera.svg";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Create the file metadata
/** @type {any} */
const metadata = {
    contentType: 'image/*'
};

interface DropZoneProps {
    onDownloaded: (url: string) => any;
}

export function ImageDropzone({ onDownloaded }: DropZoneProps) {
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const [previewUrl, setPreviewUrl] = React.useState<string | null>(null);
    const [progress, setProgress] = React.useState<number | undefined>();

    const onDrop = React.useCallback(async (acceptedFiles: any) => {
        // upload the selected file to Firebase Storage
        const file: any = acceptedFiles[0];

        // Upload file and metadata to the object 'images/mountains.jpg'
        const storageRef = ref(storage, 'images/' + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);

                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    onDownloaded(downloadURL);
                    setPreviewUrl(downloadURL);
                    setTimeout(() => {
                        setProgress(undefined);
                    }, 500);
                });
            }
        );

        // update the preview with the download URL
    }, []);

    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*' as any,
        onDrop,
    });

    return (
        <div {...getRootProps()} className="
                     m-[auto]
                     h-[220px] max-h-[220px]
                     md:w-[460px] md:max-w-[460px]
                     border
                     group-hover:bg-[#fafafa] transition duration-500
                     border-gray3 flex justify-center items-center relative">
            { !previewUrl && <img src={Camera} alt="Driver license"
                 className="group-hover:scale-110
                                left-[50%] top-[50%] translate-y-[-50%]
                                translate-x-[-50%]
                                w-[22px] h-[18px] absolute
                                transition duration-250"/> }
            { (progress ?? -1) > 0 && <div
                 className="left-[50%] top-[50%] translate-y-[-50%]
                            translate-x-[-50%] bg-white rounded
                            absolute p-4 shadow-lg">
                {`Uploading ${Math.trunc(progress ?? 0)}%`}
            </div> }
            <input {...getInputProps()} />
            { previewUrl && <img className="h-[220px] w-[460px]"
                                 src={previewUrl} alt="Preview"/> }
        </div>
    );
}
