import axios from "axios";
import {User} from "../types/user";
import {stripPhoneNumber} from "../utils/misc";

interface RegisterPayload {
    websiteGuid: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    AcceptTermsAndConditions: boolean;
    couponCode?: string | undefined;
}

export const register = async (input: RegisterPayload): Promise<any> => {
    try {
        const { data: loggedResponse } = await axios.post(
            `${process.env.REACT_APP_API_URL}/account/register`,
            input
        );
        const createdUser: User = loggedResponse.data;

        if (loggedResponse?.errorMessages.length > 0) {
            throw Error(loggedResponse.errorMessages[0].message);
        }

        console.log(
            `${createdUser.firstName} successfully signed-up, JWT token stored`
        );

        return createdUser;
    } catch (e: any) {
        if (e.message) {
            console.log(e.message);

            throw Error("Register failed");
        }
    }
};


export const sendPhoneNumberValidationSms = async (phone: string) => {
    const phoneNumber = stripPhoneNumber(phone);
    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/account/sendPhoneNumberValidationSms`,
            {
                apiKey: process.env.REACT_APP_PHONE_VALIDATION_API_KEY,
                length: 4,
                phoneNumber,
                messageTemplate: "Goldsainte code: {code}"
            });
    } catch (e: any) {
        if (e.message) {
            console.log(e.message);
            throw Error("Could not send phone SMS validation.");
        }
    }
};

export const validatePhoneNumber = async (phoneNumber: string, code: string) => {
    const {data}: any = await axios.post(`${process.env.REACT_APP_API_URL}/account/validatePhoneNumber`,
        {
            apiKey: process.env.REACT_APP_PHONE_VALIDATION_API_KEY,
            code,
            phoneNumber,
        });
    return data;
};

export const getProfile = async () => {
    const { data }: any = await axios.post(`${process.env.REACT_APP_API_URL}/account/profile`,
        {
            websiteGuid: process.env.REACT_APP_WEBSITE_GUID
        });

    return data?.data ?? {};
};

export const updateProfile = async (user: Partial<User>) => {
    try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/account/updateProfile`,
            {
                guid: user.guid,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                profilePictureUrl: user.profilePictureUrl,
                driverLicenceUrl: user.driverLicenceUrl
            });
        return data?.data ?? {};
    } catch (e: any) {
        if (e.message) {
            console.log(e.message);
            throw Error("Save profile failed");
        }
    }
};

export const redirectValidateToken =  async (token: string) => {
    try {
        const { data} = await axios.get(`${process.env.REACT_APP_CBS_TOKEN_URL}?token=${token}`);
        return true
    } catch (e: any) {
        if (e.message) {
            console.log(e.message);
            throw Error("Validating token failed.");
        }
    }
};
