import axios from "axios";

interface AddSubscriptionPayload {
    name: string,
    customerId: string,
    paymentMethodId: string,
    subtotal: number,
    tax: number,
    startDate: string,
    dueDat: string,
    currency: string,
    couponCode: string,
    members: Array<{
        firstName: string,
        lastName: string,
        address: string,
        email: string,
        phone: string,
        relationship: string
    }>,
}

export const getPaymentIntent = async (): Promise<any> => {
    try {
        const {data} = await axios.post(
            `${process.env.REACT_APP_API_URL}/payment/stripe/GetPaymentIntentForFuturePayments`,
            {
                websiteGuid: process.env.REACT_APP_WEBSITE_GUID
            }
        );
        return data;
    } catch (e) {
        console.log(e);
    }
};

export const addSubscription = async (input: AddSubscriptionPayload): Promise<any> => {
    try {
        const {data} = await axios.post(
            `${process.env.REACT_APP_API_URL}/payment/AddSubscription`,
            {
                websiteGuid: process.env.REACT_APP_WEBSITE_GUID,
                ...input
            }
        );
        return data;
    } catch (e) {
        console.log(e);
    }
};
