import {Fragment, useEffect, useRef, useState} from 'react'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import {Member} from "../types/member";
import {useRecoilState} from "recoil";
import {familyMembersState, membersState} from "../store/onboarding/onboarding";

type ModalProps = {
    show: boolean;
    member?: Member | null | undefined;
    handleClose: () => any;
    isFamily: boolean
}

export default function AddEditMember({ show, handleClose, member, isFamily }: ModalProps) {
    const [open, setOpen] = useState(show);
    const [members, setMembers] = useRecoilState(membersState);
    const [familyMembers, setFamilyMember] = useRecoilState(familyMembersState);
    const [editedMember, setEditedMember] = useState<Member | undefined | null>(member);

    function classNames(...classes: any) {
        return classes.filter(Boolean).join(' ')
    }

    useEffect(() => {
        setOpen(show);
    }, [show]);

    useEffect(() => {
        if (!open) handleClose();
    }, [open]);

    const isValid = () => {
        if (!editedMember) return false;
        return !(!editedMember.firstName?.length ||
            !editedMember.lastName?.length ||
            !editedMember.address?.length ||
            !editedMember.phone?.length ||
            !editedMember.email?.length ||
            !editedMember.relationship?.length);
    };

    const save = () => {
        const membersToUse = isFamily ? familyMembers : members;
        const setMembersToUse = isFamily ? setFamilyMember: setMembers;

        if (editedMember) {
            const existingMember = membersToUse.find((m: Member) =>
                m.phone === editedMember.phone ||
                m.email === editedMember.email);

            if (existingMember) {
                setMembersToUse(prev => {
                    return prev.map((m: Member) =>
                        m.phone === editedMember.phone ||
                        m.email === editedMember.email ? editedMember : m);
                })
            } else {
                setMembersToUse(prev => [
                    ...prev,
                    editedMember
                ])
            }
        }
        setEditedMember(null);
        handleClose();
    };

    const updateField = (field: string, value: string) => {
        setEditedMember((prev: any) => ({
            ...prev,
            [field]: value
        }))
    };

    const cancelButtonRef = useRef(null);

    const relationshipTypes = [
        'Spouse',
        'Partner',
        'Boyfriend',
        'Girlfriend',
        'Mother',
        'Father',
        'Brother',
        'Sister','Friend',
        'Other'
    ];

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-50 overflow-y-auto font-covik">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform rounded-lg bg-white
                            p-6
                            text-left shadow-xl transition-all sm:my-8">
                                <Dialog.Title as="h3" className="mb-4 text-lg font-medium leading-6 text-gray-900">
                                    { member ? 'Edit' : 'Add' } Member
                                </Dialog.Title>
                                <div className="gap-4 flex flex-col w-[250px]">
                                    <div className="flex items-center justify-between">
                                        <input
                                            type="text"
                                            required
                                            value={editedMember?.firstName ?? ''}
                                            onChange={(e) => {
                                                updateField('firstName', e.target.value);
                                            }}
                                            className="form-input py-3 px-4 block w-full leading-5 transition
                                                       placeholder-gray1 text-[16px]
                                                       duration-150 ease-in-out bg-white border-b border-gray-300
                                                       placeholder-gray-500
                                                       focus:outline-none focus:border-primary
                                                       focus:shadow-outline-primary active:bg-gray-50
                                                       px-0 py-4 active:text-gray-800"
                                            placeholder="First name"
                                        />
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <input
                                            value={editedMember?.lastName ?? ''}
                                            onChange={(e) => {
                                                updateField('lastName', e.target.value);
                                            }}
                                            type="text"
                                            required
                                            className="form-input py-3 px-4 block w-full leading-5 transition
                                                       placeholder-gray1 text-[16px]
                                                       duration-150 ease-in-out bg-white border-b border-gray-300
                                                       placeholder-gray-500
                                                       focus:outline-none focus:border-primary
                                                       focus:shadow-outline-primary active:bg-gray-50
                                                       px-0 py-4 active:text-gray-800"
                                            placeholder="Last name"
                                        />
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <input
                                            type="text"
                                            required
                                            value={editedMember?.phone ?? ''}
                                            onChange={(e) => {
                                                updateField('phone', e.target.value);
                                            }}
                                            className="form-input py-3 px-4 block w-full leading-5 transition
                                                       placeholder-gray1 text-[16px]
                                                       duration-150 ease-in-out bg-white border-b border-gray-300
                                                       placeholder-gray-500
                                                       focus:outline-none focus:border-primary
                                                       focus:shadow-outline-primary active:bg-gray-50
                                                       px-0 py-4 active:text-gray-800"
                                            placeholder="Phone"
                                        />
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <input
                                            type="text"
                                            required
                                            value={editedMember?.email ?? ''}
                                            onChange={(e) => {
                                                updateField('email', e.target.value);
                                            }}
                                            className="form-input py-3 px-4 block w-full leading-5 transition
                                                       placeholder-gray1 text-[16px]
                                                       duration-150 ease-in-out bg-white border-b border-gray-300
                                                       placeholder-gray-500
                                                       focus:outline-none focus:border-primary
                                                       focus:shadow-outline-primary active:bg-gray-50
                                                       px-0 py-4 active:text-gray-800"
                                            placeholder="Email"
                                        />
                                    </div>
                                    <div className="flex items-center justify-between mb-4">
                                        <input
                                            type="text"
                                            required
                                            value={editedMember?.address ?? ''}
                                            onChange={(e) => {
                                                updateField('address', e.target.value);
                                            }}
                                            className="form-input py-3 px-4 block w-full leading-5 transition
                                                       placeholder-gray1 text-[16px]np
                                                       duration-150 ease-in-out bg-white border-b border-gray-300
                                                       placeholder-gray-500
                                                       focus:outline-none focus:border-primary
                                                       focus:shadow-outline-primary active:bg-gray-50
                                                       px-0 py-4 active:text-gray-800"
                                            placeholder="Address"
                                        />
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <Menu as="div" className="relative inline-block text-left font-covik z-50"
                                              style={{ zIndex: 999999, width: '100%' }}>
                                            <div>
                                                <Menu.Button className="inline-flex w-full w-[100%] justify-center rounded-md border border-gray-300
                                                bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50">
                                                    { editedMember?.relationship ?? 'Relationship' }
                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                </Menu.Button>
                                            </div>

                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right
                                                rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="py-1 cursor-pointer">
                                                        {
                                                            relationshipTypes.map((relationship => {
                                                                return (<Menu.Item key={relationship}>
                                                                    {({ active }) => (
                                                                        <span
                                                                            onClick={(e) => {
                                                                                updateField('relationship', relationship)
                                                                            }}
                                                                            className={classNames(
                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                'block px-4 py-2 text-sm'
                                                                            )}
                                                                        >
                                                                            { relationship }
                                                                        </span>
                                                                    )}
                                                                </Menu.Item>)
                                                            }))
                                                        }
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>

                                </div>
                                </div>
                                <div className="mt-10">
                                    <button
                                        disabled={!isValid()}
                                        type="button"
                                        className="inline-flex justify-center rounded-md border border-transparent
                                        bg-primary px-4 py-2
                                        text-base font-medium text-white
                                        disabled:bg-gray-300
                                        focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500
                                        focus-visible:ring-offset-2"
                                        onClick={save}
                                    >
                                        Save
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
