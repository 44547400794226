import React, {useState} from "react";
import { Member } from "../types/member";
import {formatPhoneNumber} from "../utils/misc";
import AddEditMember from "./AddEditMember";

interface MemberProp {
    member: Member,
    isFamily: boolean
}

const MemberComp: React.FC<MemberProp> = ({ member, isFamily }) => {
    const [memberVisible, setMemberVisible] = useState(false);

    return <div className="mb-6">
        <div>
            <span className="text-gold leading-[20px] text-[15px] font-medium">
            { member.relationship }
            </span>
        </div>
        <div className="flex">
            <div className="flex-1">
                <div>
                    <span className="font-bold text-base leading-[24px]">
                        { member?.firstName }&nbsp;{ member?.lastName }
                    </span>
                </div>
                <div className="text-[13px] font-normal leading-[15px] flex flex-col gap-1">
                    <div>
                        <span>{ member.address }</span>
                    </div>
                    <div className="flex gap-2">
                        <span>{ formatPhoneNumber(member.phone) }</span>
                        <span>{ member.email }</span>
                    </div>
                </div>
            </div>
            <div>
                <button
                    onClick={() => setMemberVisible(true)}
                    className="border px-4 py-1 border-black
                text-[13px] font-normal
                rounded-[50px] leading-[20px]">Edit</button>
            </div>
        </div>
        <AddEditMember show={memberVisible}
                       member={member}
                       isFamily={isFamily}
                       handleClose={() => setMemberVisible(false)} />
    </div>
};

export default MemberComp;
